<template>
  <div>
    <big-title>
      Activar Interés <span>Compuesto</span>.
    </big-title>
    <vx-card title="">
      <div class="vx-row">
        <div class="vx-col xxl:w-3/4 w-full">
          <h2>¿Deseas programar un abono recurrente?</h2>
          <div class="flex mt-5">
            <vs-button class="mt-2" color="dark" type="border" @click.stop="activateInvestmentPlan">No,
              continuar sin abono recurrente</vs-button>
            <vs-button class="ml-6 mt-2" @click.stop="goToMonthlyInvestPage">Activar abono recurrente</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- DISCLAIMER INVERSION AUTOMATICA -->
    <automatic-investment-disclaimer/>
  </div>
</template>
  
<script>
import AutomaticInvestmentDisclaimer from "@components/investor/widgets/AutomaticInvestmentDisclaimer";

export default {
  name: "AskAutomaticInvestment",
  components: {
    AutomaticInvestmentDisclaimer
  },
  data() {
    return {
      investorPlan: {
        id: null,
        card_id: null,
        card_amount: null,
        reinvestment_percentage: null,
        qualifications: null,
        monthly_day: null,
        available_money: null,
        retry: null,
        min_autoinvest_project_amount_mxn: null,
        max_autoinvest_project_amount_mxn: null,
        autoinvest_applicant_payments: null,
        autoinvest_deposits: null,
      },

      factorEnabled: null,
      planWasActivated: false,
      cancelWarningPopupActive: false,
      isMounted: false,
    }
  },
  methods: {
    async goToMonthlyInvestPage() {
      await this.$router.replace({ name: 'abonoRecurrente' });
    },
    async activateInvestmentPlan() {
      this.showLoading(true);
      try {
        let payload = {
          investment_plan: "compound_interest"
        };
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/activateInvestmentPlan`, payload);
        this.successNotifDialog('Operación exitosa',
          'Has activado el interés compuesto.');
        await this.$router.replace({ name: 'planesInversion' });
      }
      catch (e) {
        let errorMssg = "No ha sido posible actualizar la información, intente más tarde.";
        if (e.response?.data) {
          errorMssg = e.response.data.message ? e.response.data.message : e.response.data;
        }
        this.errorNotifDialog('Error en guardado', errorMssg);
      }
      this.showLoading(false)
    },
  }
}
</script>
